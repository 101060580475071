import { Routes } from '@angular/router';

import { agencyCodeGuard, authorizeGuard, disclaimerGuard, roleGuard } from '@msslib/services/guards';
import { ContactPageComponent } from '@msslib/pages/contact-page/contact-page.component';
import { ErrorPageComponent } from '@msslib/pages/error-page/error-page.component';
import { roles } from '@msslib/constants';
import {
  DocumentPreferencesPageComponent,
  ExternalLoginComponent,
  FirmDetailsPageComponent,
  HomeComponent,
  OutcomeHistoryComponent,
  UserCustomProductSettingsPageComponent,
} from 'apps/clubhub/src/app/components';
import { MiBrokerComponent,
  MiExecutiveSummaryComponent,
  MiGenericComponent,
  MiIntegrationComponent,
  MiKeyPartnerComponent } from './mi';
import { lendingTypeResolver } from 'apps/shared/src/services/lending-type.resolver';
import { ClientApps } from '@msslib/models';
import { ConsumerDutyComponent } from './components/consumer-duty/consumer-duty.component';

export const routes: Routes = [
  { path: 'products/outcome', redirectTo: 'ignite/products/outcome', pathMatch: 'full' },
  { path: 'property/outcome', redirectTo: 'ignite/property/outcome', pathMatch: 'full' },
  { path: 'ignite/outcome', redirectTo: 'ignite/affordability/outcome', pathMatch: 'full' },
  {
    path: '',
    component: HomeComponent,
    canActivate: [authorizeGuard, roleGuard],
    data: { roles: [roles.broker, roles.staff, roles.procFeePreview, roles.ignite] },
  },
  {
    path: 'smartrfit',
    redirectTo: 'ignite',
  },
  {
    path: 'ignite',
    loadChildren: () => import('./ignite/ignite.module').then((m) => m.IgniteModule),
    canActivate: [disclaimerGuard],
    data: { client: ClientApps.ClubHub },
  },
  {
    path: 'procfee',
    loadChildren: () => import('./proc-fee/proc-fee.module').then((m) => m.ProcFeeModule),
    canActivate: [authorizeGuard, agencyCodeGuard],
    data: { client: ClientApps.ClubHub },
  },
  {
    path: 'consumer-duty',
    canActivate: [authorizeGuard, roleGuard],
    component: ConsumerDutyComponent,
    data: { client: ClientApps.ClubHub, roles: [roles.viewConsumerDuty] },
  },
  {
    path: 'mi',
    component: MiGenericComponent,
    canActivate: [authorizeGuard, roleGuard],
    data: { roles: [roles.broker, roles.brokerMi] },
  },
  {
    path: 'mi/integration',
    component: MiIntegrationComponent,
    canActivate: [authorizeGuard, roleGuard],
    data: { roles: [roles.broker, roles.brokerIntegrationMi] },
  },
  {
    path: 'mi/key-partner',
    component: MiKeyPartnerComponent,
    canActivate: [authorizeGuard, roleGuard],
    data: { roles: [roles.broker, roles.brokerNewKeyPartnerMI] },
  },
  {
    path: 'mi/executive-market-summary',
    component: MiExecutiveSummaryComponent,
    canActivate: [authorizeGuard, roleGuard],
    data: { roles: [roles.brokerPaidMI, roles.executiveMarketSummaryMI]},
  },
  {
    path: 'mi/broker-ignite',
    component: MiBrokerComponent,
    canActivate: [authorizeGuard, roleGuard],
    data: { roles: [roles.brokerPaidMI, roles.brokerIgniteMI]},
  },
  {
    path: 'outcomehistory',
    component: OutcomeHistoryComponent,
    canActivate: [authorizeGuard],
    resolve: { lendingTypes: lendingTypeResolver },
  },
  { path: 'contact', component: ContactPageComponent },
  { path: 'loginexternal', component: ExternalLoginComponent },
  { path: 'documentpreferences', component: DocumentPreferencesPageComponent },
  { path: 'productpreferences', component: UserCustomProductSettingsPageComponent },
  { path: 'firmdetails', component: FirmDetailsPageComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', redirectTo: '' },
];
